.canvas {
	position: relative;
	box-shadow: 10px 10px 42px 0 rgba(0,0,0,0.75);
  width: 60vw;
  height: 60vw;
  max-width: 400px;
  max-height: 400px;
  margin: 0 auto;
}

@media only screen and (orientation: landscape) {
  .canvas {
    width: 40vw;
    height: 40vw;
  }
}

.canvas:after, .canvas:before{
	mix-blend-mode: multiply;
	filter:none;
	z-index: -1;
	content:'';
	width:calc(100% + (50px * 2));
	height:calc(100% + (50px * 2));
	position: absolute;
	display: block;
	animation: border 2s ease-in-out infinite;
	transform:translateX(-50px) translateY(-50px);
}

@keyframes border {
  0%, 100% {
    -webkit-clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
    clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
  }
  50% {
    -webkit-clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
    clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
  }
}

.canvas:after {
	animation-delay: -1s;
	background-color: #93e1d8;
	clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
}

.canvas:before {
	background-color: #AA4465;
	  clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
}